import React, { memo } from 'react'
import addToClipBoardButtonConnector from 'root/src/client/logic/app/connectors/addToClipBoardButtonConnector'
import { CopyToClipboardBase } from 'root/src/client/web/base/CopyToClipboardBase'

const style = {}

const CopyToClipboardAlerted = memo(({ url, displayEventSuccessMessage, children, className }) => {
	const onCopied = async (event) => {
		displayEventSuccessMessage(event, 'Copied!')
	}

	return (
		<div className={className}>
			<CopyToClipboardBase
				text={url}
				onCopied={onCopied}
			>
				{children}
			</CopyToClipboardBase>
		</div>
	)
})

export default addToClipBoardButtonConnector(CopyToClipboardAlerted, style, 'MuiCopyToClipboardAlerted')
