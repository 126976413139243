import reduxConnector from 'root/src/shared/util/reduxConnector'

import displayEventSuccessMessage from 'root/src/client/logic/app/thunks/displayEventSuccessMessage'

export default reduxConnector(
	[],
	[
		['displayEventSuccessMessage', displayEventSuccessMessage],
	],
)
