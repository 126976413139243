import replace from 'ramda/src/replace'

export default (text) => {
	if (!text) {
		return undefined
	}
	const map = {
		'&amp;': '&',
		'&lt;': '<',
		'&gt;': '>',
		'&quot;': '"',
		'&#039;': '\'',
	}

	return replace(/(&amp;|&lt;|&gt;|&quot;|&#039;)/g, m => map[m], text)
}
