import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import BookmarkIcon from 'root/src/client/public/icons/ic-bookmark.svg'

const style = {
	pinWrapper: {
		position: 'relative',
		transition: '0s',
		'&:hover > div': {
			visibility: 'visible',
			opacity: '1',
		},
		'&:hover > img': {
			filter: 'brightness(100%)',
		},
	},
	pin: {
		margin: 0,
		width: 22,
		height: 22,
		filter: 'brightness(60%)',
		transition: '.050s ease',
		'&:hover': {
			filter: 'brightness(150%)',
		},
	},
	pinActive: {
		filter: 'brightness(100%)',
	},
	tooltipWrapper: {
		visibility: 'hidden',
		opacity: 0,
		transition: '.1s opacity',
		position: 'absolute',
		marginTop: 3,
		marginLeft: 10,
		pointerEvents: 'none',
		boxShadow: '0px 0px 8px #000',
		zIndex: 10,
	},
	tooltip: {
		zIndex: 10,
		fontSize: 13,
		position: 'relative',
		width: 120,
		padding: [[10, 5]],
		borderRadius: 4,
		textAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#132D4A',
		'&::after': {
			position: 'absolute',
			top: -5,
			right: 0,
			content: '""',
			borderBottom: '10px solid #132D4A',
			borderLeft: '10px solid transparent',
			borderRight: '10px solid transparent',
		},
	},
	pinImageWrapper: {
		width: 22,
		height: 22,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}

const Pin = ({
	classes, active, onClick,
}) => {
	const [coords, setCoords] = useState()
	const pinRef = useRef()
	const tooltipRef = useRef()

	const tooltipWidth = useMemo(() => (tooltipRef.current
		? tooltipRef.current.clientWidth
		: 0), [tooltipRef.current])

	useEffect(() => {
		if (!pinRef.current) return
		const { width, height } = pinRef.current.getBoundingClientRect()
		setCoords({ x: width / 2 - tooltipWidth, y: height })
	}, [pinRef.current])

	return (
		<div
			onClick={onClick}
			className={classes.pinWrapper}
		>
			<div
				ref={tooltipRef}
				className={classes.tooltipWrapper}
				style={{
					top: coords?.y || 0,
					left: coords?.x || 0,
				}}
			>
				<div className={classes.tooltip}>
					{active ? 'Unpin this dare' : 'Only display this dare on overlay'}
				</div>
			</div>
			<div className={classes.pinImageWrapper}>
				<img
					ref={pinRef}
					className={classNames(
						classes.pin,
						{ [classes.pinActive]: active },
					)}
					src={BookmarkIcon.src}
					alt="Pin"
				/>
			</div>
		</div>
	)
}

export default withStyles(style)(Pin)
