import { memo, useState } from 'react'
import { ternary } from 'root/src/shared/util/ramdaPlus'
import goToClaimProjectHandler from 'root/src/client/logic/project/handlers/goToClaimProjectHandler'
import {
	projectAcceptedKey,
	projectApprovedKey,
	projectDeliveredKey,
} from 'root/src/shared/constants/keys'
import ButtonNew from 'root/src/client/web/base/ButtonNew'
import Link from 'root/src/client/web/base/Link'
import {
	DELIVERY_DARE_FORM_ROUTE_ID,
	PLEDGE_PROJECT_ROUTE_ID,
	VIEW_PROJECT_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import PlayIcon from 'root/src/client/public/icons/ic-play-new.svg'
import format2Decimals from 'root/src/shared/util/format2Decimals'
import { useProjectCard } from 'root/src/client/web/list/listItemComponents/project/hooks/useProjectCard'
import * as BlackCard from 'root/src/client/v2/common/components/BlackCard/BlackCard'
import { withProjectCardStyle } from 'root/src/client/web/list/listItemComponents/style'
import { ProjectCardRewardRemaining } from 'root/src/client/web/list/listItemComponents/project/ProjectCardRewardRemaining'

export const rewardForLabels = {
	fans: 'Reward for Fans',
	creator: 'Reward for Creator',
}

const getRewardForText = (rewardFor, isDelivered, brandProduct) => {
	if (!brandProduct) {
		return 'PLEDGED'
	}

	if (!isDelivered) {
		return 'REWARD'
	}

	if (!rewardFor) {
		return 'REWARD EARNED'
	}

	return rewardForLabels[rewardFor]
}

const ProjectSummary = withProjectCardStyle(() => {
	const {
		project: {
			rewardFor,
			brandProduct,
			pledgeAmount,
			projectUrl,
			status,
		},
	} = useProjectCard()

	const isDelivered = status === projectDeliveredKey
	const brandProductName = brandProduct
		? `(${brandProduct.amount}) ${brandProduct.name}`
		: `$${format2Decimals(pledgeAmount)}`

	return (
		<div className="flex w-full px-4 flex-initial gap-2 justify-between items-center">
			<div className="flex flex-initial w-3/5 flex-col">
				<BlackCard.HighlightedSection
					title={getRewardForText(rewardFor, isDelivered, brandProduct)}
					text={<span className="truncate" title={brandProductName}>{brandProductName}</span>}
				/>
				<ProjectCardRewardRemaining />
			</div>
			{status === projectDeliveredKey && (
				<Link routeId={VIEW_PROJECT_ROUTE_ID} routeParams={{ recordId: projectUrl }}>
					<div className="flex flex-col justify-center items-center gap-2">
						<p style={{
							fontFamily: 'Roboto',
							fontSize: 12,
							fontWeight: 'bold',
							color: '#7A3AED',
							margin: 0,
							textAlign: 'center',
							whiteSpace: 'nowrap',
						}}
						>
							DARE DETAILS
						</p>
						<img width={22} height={22} src={PlayIcon.src} alt="Play" />
					</div>
				</Link>
			)}
		</div>
	)
})

const ProjectPledgeButton = () => {
	const { project: { projectUrl } } = useProjectCard()

	return (
		<div style={{ width: '70%' }}>
			<Link routeId={PLEDGE_PROJECT_ROUTE_ID} routeParams={{ recordId: projectUrl }}>
				<ButtonNew htmlType="button">
					<span style={{ fontFamily: 'Roboto', fontSize: 15, letterSpacing: '.02em', textTransform: 'uppercase', fontWeight: 400 }}>
						Pledge to Dare
					</span>
				</ButtonNew>
			</Link>
		</div>
	)
}

const ProjectDeliverDareButton = () => {
	const { project: { projectUrl } } = useProjectCard()
	return (
		<div style={{ width: '70%' }}>
			<Link
				routeId={DELIVERY_DARE_FORM_ROUTE_ID}
				routeParams={{ recordId: projectUrl }}
			>
				<ButtonNew htmlType="button">
					Deliver Dare Video
				</ButtonNew>
			</Link>
		</div>
	)
}

const ProjectManageActionsButton = () => {
	const [isLoading, setIsLoading] = useState(false)
	const {
		project: { brandProduct, projectUrl },
		actions: { onClaimSponsoredProject },
	} = useProjectCard()
	return (
		<div style={{ width: '70%' }}>
			<ButtonNew
				htmlType="button"
				isLoading={isLoading}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					ternary(
						brandProduct,
						async () => {
							setIsLoading(true)
							await onClaimSponsoredProject()
							setIsLoading(false)
						},
						() => goToClaimProjectHandler(projectUrl),
					)()
				}}
			>
				{brandProduct ? 'Accept' : 'Set Goal'}
			</ButtonNew>
		</div>
	)
}

export const ProjectCardButton = memo(() => {
	const {
		project: { status, brandProduct },
		extraData: { isUserOneOfAssignees },
	} = useProjectCard()

	if (status === projectDeliveredKey) {
		if (brandProduct?.amount === 0) {
			return null
		}
		return <ProjectSummary />
	}

	if (isUserOneOfAssignees) {
		if (status === projectAcceptedKey) {
			return <ProjectDeliverDareButton />
		}

		if (status === projectApprovedKey && isUserOneOfAssignees) {
			return <ProjectManageActionsButton />
		}
	}

	if (brandProduct) {
		if (brandProduct.amount === 0) {
			return null
		}

		return <ProjectSummary />
	}

	return <ProjectPledgeButton />
})
