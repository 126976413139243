import { memo, useState } from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from 'root/src/client/web/base/MenuItem'
import ShareIcon from '@material-ui/icons/Share'
import clipboard from 'root/src/client/public/icons/clipboard.png'
import withStyles from '@material-ui/core/styles/withStyles'
import CopyToClipboardAlerted from 'root/src/client/web/base/CopyToClipboardAlerted'
import Menu from 'root/src/client/web/base/Menu'
import { comparePropsFn } from 'root/src/shared/util/stateCompare'
import { colorsNew, purple } from 'root/src/client/web/commonStyles'

import {
	TwitterShareButton, FacebookShareButton, RedditShareButton, VKShareButton,
	TwitterIcon, FacebookIcon, RedditIcon, VKIcon,
} from 'react-share'

const ShareMenuItems = memo(({ url }) => [
	<MenuItem key="twitter">
		<TwitterShareButton url={url}>
			<div className="layout-row layout-align-start-center">
				<TwitterIcon width="32" height="32" size={32} round />{'\u00A0'}
				<span>Share on Twitter</span>
			</div>
		</TwitterShareButton>
	</MenuItem>,
	<MenuItem key="facebook">
		<FacebookShareButton url={url}>
			<div className="layout-row layout-align-start-center">
				<FacebookIcon width="32" height="32" size={32} round />{'\u00A0'}
				<span>Share on Facebook</span>
			</div>
		</FacebookShareButton>
	</MenuItem>,
	<MenuItem key="reddit">
		<RedditShareButton url={url}>
			<div className="layout-row layout-align-start-center">
				<RedditIcon width="32" height="32" size={32} round />{'\u00A0'}
				<span>Share on Reddit</span>
			</div>
		</RedditShareButton>
	</MenuItem>,
	<MenuItem key="vk">
		<VKShareButton url={url}>
			<div className="layout-row layout-align-start-center">
				<VKIcon width="32" height="32" size={32} round />{'\u00A0'}
				<span>Share on VK</span>
			</div>
		</VKShareButton>
	</MenuItem>,
	<MenuItem key="clipboard" width="32" height="32" style={{ overflow: 'visible' }}>
		<CopyToClipboardAlerted url={url}>
			<div className="layout-row layout-align-start-center">
				<img src={clipboard.src} width="32" height="32" alt="add_to_clipboard" />{'\u00A0'}
				<span style={{ fontSize: 16 }}>Copy to clipboard</span>
			</div>
		</CopyToClipboardAlerted>
	</MenuItem>,
])

const styles = {

}

export default withStyles(styles, { name: 'MuiShareMenu' })(memo(({ url, onClose, onOpen, className, isWidget }) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleClose = () => { if (onClose) { onClose() } return setAnchorEl(null) }

	const handleClick = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (onOpen) { onOpen() } return setAnchorEl(e.currentTarget)
	}

	const dynamicStyles = isWidget
		? {
			iconContainer: {
				'& svg': {
					fontSize: 30,
				},

			},
		}
		: {
			iconContainer: {
				padding: 8,
				'&:hover': {
					backgroundColor: colorsNew.dark2,
				},
				'& svg': {
					fontSize: 18,
					'&:hover': {
						fill: purple,
					},
				},
			},
		}

	return (
		<div className={className}>
			<IconButton
				aria-label="More"
				aria-haspopup="true"
				onClick={handleClick}
				css={dynamicStyles.iconContainer}
			>
				<ShareIcon css={dynamicStyles.icon} />
			</IconButton>
			<Menu
				open={open}
				onClick={(e) => {
					e.preventDefault()
					setAnchorEl(null)
				}}
				onClose={handleClose}
				setOpenFn={setAnchorEl}
				componentRef={anchorEl}
				width={220}
				isWidget={isWidget}
			>
				<ShareMenuItems url={url} />
			</Menu>
		</div>
	)
}, comparePropsFn(['url'])))
