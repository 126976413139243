import { useProjectCard } from 'root/src/client/web/list/listItemComponents/project/hooks/useProjectCard';
import { brandProductTypesEnum } from 'root/src/shared/constants/dropEvent';
import { RewardProgressBar } from 'root/src/client/web/list/listItemComponents/dare/DareCardBody';
export const ProjectCardRewardRemaining = () => {
    var _a, _b;
    const { project } = useProjectCard();
    if (!(project === null || project === void 0 ? void 0 : project.capacity) || !((_a = project.brandProduct) === null || _a === void 0 ? void 0 : _a.amount)) {
        return null;
    }
    const isCustomProduct = ((_b = project.brandProduct) === null || _b === void 0 ? void 0 : _b.type) === brandProductTypesEnum.customProduct;
    const { available, used } = project.capacity;
    const total = available + used;
    return (<div className="flex flex-col gap-1">
			<RewardProgressBar remaining={available} total={total}/>
			<span className="font-medium text-light-4 text-xs">
				{isCustomProduct ? 'Unlimited' : `${available} Remaining`}
			</span>
		</div>);
};
