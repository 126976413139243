import length from 'ramda/src/length'
import clipHandler from 'root/src/client/logic/project/handlers/clipHandler'
import { wideCardBreakpoint } from 'root/src/client/web/list/listItemComponents/style'

export default (projectAssigneesName, windowWidth) => {
	const clipRemoveResolutionRange = [wideCardBreakpoint, 332]
	const testCase = (
		windowWidth <= clipRemoveResolutionRange[0]
	&& windowWidth >= clipRemoveResolutionRange[1]
	)

	let username
	switch (length(projectAssigneesName)) {
	case 1:
		return projectAssigneesName[0]
	case 2:
		if (testCase) {
			[username] = projectAssigneesName
		} else {
			username = clipHandler(projectAssigneesName[0], 11, 10)
		}
		return `${username} and 1 other`
	default:
		if (testCase) {
			[username] = projectAssigneesName
		} else {
			username = clipHandler(projectAssigneesName[0], 11, 7)
		}
		return `${username} and ${length(projectAssigneesName) - 1} others`
	}
}
