import setVisibleSuccessMessage from 'root/src/client/logic/app/actions/setVisibleSuccessMessage'
import getCursorPosition from 'root/src/client/util/getCursorePosition'

export default (event, message) => (dispatch) => {
	const position = getCursorPosition(event)
	dispatch(setVisibleSuccessMessage(true, position, message))
	setTimeout(() => {
		dispatch(setVisibleSuccessMessage(false, { x: 0, y: 0 }, ''))
	}, 2500)
}
