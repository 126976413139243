import React, { memo } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const style = {
	listItem: {
		listStyle: 'none',
		color: 'rgba(0, 0, 0, 0.87)',
		width: 'auto',
		height: 24,
		padding: [[13, 20]],
		overflow: 'hidden',
		fontSize: '1rem',
		boxSizing: 'content-box',
		fontWeight: 400,
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		lineHeight: '1.5em',
		whiteSpace: 'nowrap',
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'none',
			backgroundColor: 'rgba(0, 0, 0, 0.08)',
		},
	},
}

const MenuItem = memo(({ children, classes, ...props }) => (
	<li {...props} className={classes.listItem}>
		{children}
	</li>
))

export default withStyles(style, { name: 'MuiMenuItem' })(MenuItem)
