import { addHttpsProtocol } from 'root/src/shared/util/protocolUtil'
import { useWindowSize } from 'root/src/client/v2/common/hooks/useWindowSize'
import { useProjectCard } from 'root/src/client/web/list/listItemComponents/project/hooks/useProjectCard'
import classNames from 'classnames'
import projectCardAssigneesNamesHandler
	from 'root/src/client/web/list/util/projectCardAssigneesNamesHandler'
import { withProjectCardStyle } from 'root/src/client/web/list/listItemComponents/style'
import { PlatformIcon, PlatformIconColor } from 'root/src/client/web/base/CustomButton/PlatformIcon'

const MAX_ASSIGNEE_IMAGES_ON_CARD = 3

const ProjectCardAssigneeImage = withProjectCardStyle(({ classes, isLive, assignee }) => (
	<div className={classes.assigneeImg}>
		{isLive && (
			<div className={classes.liveAlert}>
				LIVE
			</div>
		)}
		<img
			className={classes.assigneeImage}
			src={addHttpsProtocol(assignee.image)}
			alt={assignee.displayName}
			width="26"
			height="26"
		/>
		<div className="flex absolute justify-center items-center -right-1 -bottom-1 p-1 bg-white shadow rounded-full">
			<PlatformIcon size="1x" color={PlatformIconColor.Colorized} platform={assignee.platform} />
		</div>
		{/* <div className={classes.imageDot} /> */}
	</div>
))

export const ProjectCardSeparator = withProjectCardStyle(({ classes }) => {
	const [width] = useWindowSize()

	const {
		project: { assignees },
		extraData: { liveAssignees },
	} = useProjectCard()

	return (
		<div className={classNames(
			'layout-row layout-align-center',
			classes.projectAssigne,
			classes.overlay,
		)}
		>
			{assignees.map((assignee, i) => (i < MAX_ASSIGNEE_IMAGES_ON_CARD && (
				<ProjectCardAssigneeImage
					/* eslint-disable-next-line react/no-array-index-key */
					key={assignee.platformId}
					assignee={assignee}
					isLive={liveAssignees?.[i]}
				/>
			)))}
			<p className={classes.assigneesNames}>
				{projectCardAssigneesNamesHandler(assignees.map(assignee => assignee.displayName), width)}
			</p>
		</div>
	)
})
